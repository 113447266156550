<template>
  <div class="pk-app">
    <div class="pk-head">
      <div class="left">
        <div class="logo"></div>
        <!-- <div class="text">{{currentTime}}</div> -->
      </div>
      <div class="center">坤达安销售战报</div>
      <div class="right">
        倒计时
        <span class="day">{{ diffDay }}</span>天
      </div>
    </div>
    <div class="pk-nav">
      <div class="pk-nav-between">
        <div :class="'pk-nav-title' + (showGoal ? ' select' : '')" @click="changeGoal(true)"> 目标完成率 </div>
        <div :class="'pk-nav-title' + (!showGoal ? ' select' : '')" @click="changeGoal(false)"> 当前商机数 </div>
      </div>
      <div class="pk-nav-center">
        <vue-seamless-scroll :class-option="optionLeft" :data="news" class="seamless-warp" v-if="news">
          <ul class="item">
            <li v-for="(item,index) in news" :key="index" v-text="item.title"></li>
          </ul>
        </vue-seamless-scroll>
      </div>
      <div class="pk-nav-between">
        <div :class="'pk-nav-title' + (showCost ? ' select' : '')" @click="changeCost(true)"> 费用使用排名 </div>
        <div :class="'pk-nav-title' + (!showCost ? ' select' : '')" @click="changeCost(false)"> 应收账款排名 </div>
      </div>
    </div>
    <div class="pk-body">
      <div class="pk-body-between" v-if="showGoal">
        <div class="pk-body-tbody" v-if="goalUser">
          <div :class="'pk-body-trow r' + (index + 1)" v-for="(item, index) in goalUser" :key="index">
            <div class="col1" v-if="index > 2"><span>{{ index + 1 }}</span></div>
            <div :class="'col1 i' + (index + 1)" v-else></div>
            <div class="col2">{{ item.name }}</div>
            <div class="col3">{{ item.total }}</div>
          </div>
        </div>
      </div>
      <div class="pk-body-between" v-else>
        <div class="pk-body-tbody" v-if="chanceTeam && chanceUser">
          <div :class="'pk-chance-team'" v-for="(item, index) in chanceTeam" :key="index">
            <div class="pk-chance-tcol1">{{ index + 1 }}</div>
            <div class="pk-chance-tcol2">{{ item.name }}</div>
            <div class="pk-chance-tcol3">数量：{{ item.count }}</div>
            <div class="pk-chance-tcol4">金额：{{ item.total }}</div>
          </div>
          <div :class="'pk-chance-user r' + (index + 1)" v-for="(item, index) in chanceUser" :key="index">
            <div class="pk-chance-ucol1">{{ index + 1 }}</div>
            <div class="pk-chance-ucol2">{{ item.name }}</div>
            <div class="pk-chance-ucol3">{{ item.count }}</div>
            <div class="pk-chance-ucol4">{{ item.total }}</div>
          </div>
        </div>
      </div>
      <div class="pk-body-center">
        <div class="pk-body-team">
          <div :class="'pk-body-box' + (index == 0 ? ' margin-left0' : '')" v-for="(item, index) in goalTeam" :key="index">
            <div :class="'top top' + (index + 1)">Top{{ index + 1 }}</div>
            <div class="img" :style="'background-image: url(/images/pk-'+item.name+'.png);'">
              <div class="crown" v-if="index==0"></div>
            </div>
            <div class="team">{{ item.name }}</div>
            <div class="process-text">目标完成 {{ item.total }}</div>
            <div class="process-chart">
              <div class="process-chart-value" :style="'width:' + item.total + ''"></div>
            </div>
          </div>
        </div>
        <div class="pk-body-visit">
          <div class="body-visit-radio">
            <RadioGroup v-model="visitTime" type="button" size="small" @on-change="getVisit()">
              <Radio label="周"></Radio>
              <Radio label="月"></Radio>
              <Radio label="年"></Radio>
              <Radio label="总"></Radio>
            </RadioGroup>
          </div>
          <div class="body-visit-rank">
            <div class="visit-rank-title">客户拜访排名</div>
            <div class="visit-rank-team">
              <div :class="'visit-team-box c' + (index + 1)" v-for="(item, index) in visitTeam" :key="index">
                <div class="visit-team-img"></div>
                <div class="visit-team-name">{{ item.name }}</div>
                <div class="visit-team-count">{{ item.total }}</div>
              </div>
            </div>
            <div class="visit-rank-user">
              <div class="visit-user-trow">
                <div class="col1">排名</div>
                <div class="col2">姓名</div>
                <div class="col3">拜访</div>
              </div>
              <div :class="'visit-user-trow r' + (index + 1)" v-for="(item, index) in visitUser" :key="index">
                <div class="col1">{{ index + 1 }}</div>
                <div class="col2">{{ item.name }}</div>
                <div class="col3">{{ item.total }}次</div>
              </div>
            </div>
          </div>
          <div class="body-plan-rank">
            <div class="plan-rank-title">工作总结计划</div>
            <div class="plan-rank-team">
              <div :class="'plan-team-box c' + (index + 1)" v-for="(item, index) in planTeam" :key="index">
                <div class="plan-team-img"></div>
                <div class="plan-team-name">{{ item.name }}</div>
                <div class="plan-team-count">完成率：<span>{{ item.rate }}</span></div>
                <div class="plan-team-count">变化率：<span>{{ item.temp }}</span></div>
              </div>
            </div>
            <div class="plan-rank-user">
              <div class="plan-user-trow">
                <div class="col1">排名</div>
                <div class="col2">姓名</div>
                <div class="col3">完成率</div>
                <div class="col4">变化率</div>
              </div>
              <div :class="'plan-user-trow r' + (index + 1)" v-for="(item, index) in planUser" :key="index">
                <div class="col1">{{ index + 1 }}</div>
                <div class="col2">{{ item.name }}</div>
                <div class="col3">{{ item.rate }}</div>
                <div class="col4">{{ item.temp }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pk-body-between" v-if="showCost">
        <div class="pk-cost-team">
          <div :class="'cost-team-box c' + (index + 1)" v-for="(item, index) in costTeam" :key="index">
            <div class="cost-team-rank">NO{{ index + 1 }}</div>
            <div class="cost-team-img" :style="'background-image: url(/images/pk-'+item.name+'.png);'"></div>
            <div class="cost-team-name">{{ item.name }}</div>
          </div>
        </div>
        <div class="pk-cost-user">
          <div class="cost-user-box" v-for="(item, index) in costUser" :key="index">
            <div class="cost-user-title">
              <div class="cost-user-rank">NO{{ index + 1 }}</div>
              <div class="cost-user-name">{{ item.name }}</div>
            </div>
            <div class="cost-user-rate" :style="'width:' + item.total + ''"></div>
          </div>
        </div>
      </div>
      <div class="pk-body-between" v-else>
        <div class="pk-cost-team">
          <div :class="'cost-team-box c' + (index + 1)" v-for="(item, index) in receivableTeam" :key="index">
            <div class="cost-team-rank">NO{{ index + 1 }}</div>
            <div class="cost-team-img" :style="'background-image: url(/images/pk-'+item.name+'.png);'"></div>
            <div class="cost-team-name">{{ item.name }}</div>
          </div>
        </div>
        <div class="pk-cost-user">
          <div class="cost-user-box" v-for="(item, index) in receivableUser" :key="index">
            <div class="cost-user-title">
              <div class="cost-user-rank">NO{{ index + 1 }}</div>
              <div class="cost-user-name">{{ item.name }}</div>
            </div>
            <div class="cost-user-rate" :style="'width:' + item.total + ''"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { GetTeamRankBoard, GetTeamVisitRank } from "@/api";
import { loadDetailAction, loadDataAction } from "@/utils/action.js";
export default {
  name: "salerpk",
  data: function () {
    return {
      goalUser: null,
      goalTeam: null,
      visitUser: null,
      visitTeam: null,
      costUser: null,
      costTeam: null,
      chanceUser: null,
      chanceTeam: null,
      receivableUser: null,
      receivableTeam: null,
      planUser: null,
      planTeam: null,
      currentTime: dayjs().format("YYYY-MM-DD"),
      diffDay: 0,
      showGoal: true,
      showCost: true,
      visitTime: "总",
      news: []
    };
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 1
      }
    }
  },
  methods: {
    initPage: function () {
      loadDetailAction(this, GetTeamRankBoard, {}, (data) => {
        this.goalUser = data.goalUser;
        this.goalTeam = data.goalTeam;
        this.visitUser = data.visitUser;
        this.visitTeam = data.visitTeam;
        this.costUser = data.costUser;
        this.costTeam = data.costTeam;
        this.chanceUser = data.chanceUser;
        this.chanceTeam = data.chanceTeam;
        this.receivableUser = data.receivableUser;
        this.receivableTeam = data.receivableTeam;
        this.planUser = data.planUser;
        this.planTeam = data.planTeam;
        this.diffDay = data.diffDay;
        this.news = data.news;
        console.log(data);
      });
    },
    getVisit: function () {
      loadDataAction(this, GetTeamVisitRank, { time: this.visitTime }, (data) => {
        this.visitUser = data.visitUser;
        this.visitTeam = data.visitTeam;
        this.planUser = data.planUser;
        this.planTeam = data.planTeam;
      });
    },
    changeGoal: function (b) {
      this.showGoal = b;
    },
    changeCost: function (b) {
      this.showCost = b;
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>
<style scoped>
.seamless-warp {
  overflow: hidden;
  height: 50px;
  width: 900px;
}
.seamless-warp ul.item {
  width: 1500px;
}
.seamless-warp ul.item li {
  float: left;
  margin-right: 30px;
}

.pk-chance-team {
  display: flex;
  flex-direction: row;
  height: 50px;
  line-height: 50px;
  border: 1px solid #475196;
  border-radius: 25px;
  margin-bottom: 5px;
}
.pk-chance-tcol1 {
  width: 50px;
  text-align: center;
}
.pk-chance-tcol2 {
  width: 95px;
  text-align: left;
}
.pk-chance-tcol3 {
  width: 95px;
  text-align: left;
}
.pk-chance-tcol4 {
  display: flex;
  flex: 1;
}
.pk-chance-user {
  display: flex;
  flex-direction: row;
  height: 32px;
  line-height: 32px;
  margin-right: 20px;
  font-size: 16px;
}
.pk-chance-ucol1 {
  width: 60px;
  text-align: center;
}
.pk-chance-ucol2 {
  width: 100px;
  text-align: left;
}
.pk-chance-ucol3 {
  width: 100px;
  text-align: right;
}
.pk-chance-ucol4 {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.pk-nav {
  height: 42px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pk-nav-center {
  display: flex;
  flex: 1;
  background-image: url("../../../static/board/pk-box-news.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 20px;
  line-height: 42px;
  color: #00f3f7;
  padding: 0 20px;
}
.pk-nav-between {
  background-image: url("../../../static/board/pk-box-title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 23%;
  padding: 1px;
}
.pk-nav-title {
  width: 50%;
  height: 42px;
  line-height: 42px;
  cursor: pointer;
}
.pk-nav-title.select {
  background: #0a7ed0;
}
.body-visit-rank {
  width: 35%;
  display: flex;
  margin: 10px 0 5px 10px;
  padding: 10px 20px;
  flex-direction: column;
  background-image: url("../../../static/board/pk-box-visit.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.visit-rank-title {
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin-left: 20px;
}
.visit-rank-team {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #2d99ff;
}
.visit-rank-user {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}
.visit-user-trow {
  display: flex;
  flex-direction: row;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
}
.visit-user-trow .col1 {
  width: 40px;
  text-align: center;
}
.visit-user-trow .col2 {
  display: flex;
  flex: 1;
  padding-left: 20px;
}
.visit-user-trow .col3 {
  width: 120px;
  padding-right: 20px;
  text-align: right;
}
.visit-team-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}
.visit-team-img {
  width: 21px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.visit-team-box.c1 .visit-team-img {
  background-image: url("../../../static/board/pk-visit-r1.png");
}
.visit-team-box.c2 .visit-team-img {
  background-image: url("../../../static/board/pk-visit-r2.png");
}
.visit-team-box.c3 .visit-team-img {
  background-image: url("../../../static/board/pk-visit-r3.png");
}
.visit-team-box.c4 .visit-team-img {
  background-image: url("../../../static/board/pk-visit-r4.png");
}
.visit-team-box.c1 .visit-team-count {
  font-size: 18px;
  color: #fde900;
}
.visit-team-box.c2 .visit-team-count {
  font-size: 18px;
  color: #d462f5;
}
.visit-team-box.c3 .visit-team-count {
  font-size: 18px;
  color: #1991f6;
}
.visit-team-box.c4 .visit-team-count {
  font-size: 18px;
  color: #00fefe;
}
.body-plan-rank {
  display: flex;
  flex: 1;
  margin: 10px 10px 5px 0;
  padding: 10px 20px;
  flex-direction: column;
  background-image: url("../../../static/board/pk-box-plan.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.plan-rank-title {
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin-left: 10px;
}
.plan-rank-team {
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #2d99ff;
  flex-wrap: wrap;
}
.plan-rank-user {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}
.plan-user-trow {
  display: flex;
  flex-direction: row;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
}
.plan-user-trow .col1 {
  width: 40px;
  text-align: center;
}
.plan-user-trow .col2 {
  display: flex;
  flex: 1;
  padding-left: 20px;
}
.plan-user-trow .col3 {
  width: 120px;
  padding-right: 20px;
  text-align: right;
}
.plan-user-trow .col4 {
  width: 120px;
  padding-right: 20px;
  text-align: right;
}
.visit-user-trow.r1,
.plan-user-trow.r1 {
  color: #fde900;
}
.visit-user-trow.r2,
.plan-user-trow.r2 {
  color: #d462f5;
}
.visit-user-trow.r3,
.plan-user-trow.r3 {
  color: #1991f6;
}
.visit-user-trow.r4,
.plan-user-trow.r4 {
  color: #00fefe;
}
.plan-team-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 50%;
  width: 50%;
  font-size: 14px;
  padding-right: 10px;
}
.plan-team-img {
  width: 21px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.plan-team-box.c1 .plan-team-img {
  background-image: url("../../../static/board/pk-visit-r1.png");
}
.plan-team-box.c2 .plan-team-img {
  background-image: url("../../../static/board/pk-visit-r2.png");
}
.plan-team-box.c3 .plan-team-img {
  background-image: url("../../../static/board/pk-visit-r3.png");
}
.plan-team-box.c4 .plan-team-img {
  background-image: url("../../../static/board/pk-visit-r4.png");
}
.plan-team-name {
  width: 80px;
  text-align: left;
}
.plan-team-box.c1 span {
  color: #fde900;
}
.plan-team-box.c2 span {
  color: #d462f5;
}
.plan-team-box.c3 span {
  color: #1991f6;
}
.plan-team-box.c4 span {
  color: #00fefe;
}
/* .plan-team-count {
  font-size: 18px;
  color: #ff5a5a;
} */

.pk-app {
  display: flex;
  height: 100%;
  color: #fff;
  flex-direction: column;
  background-image: url("../../../static/board/pk-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 40px 40px;
  font-size: 18px;
}
.pk-head {
  display: flex;
  height: 100px;
  flex-direction: row;
  justify-content: space-between;
}
.pk-head .left {
  width: 200px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.pk-head .left .logo {
  background-image: url("../../../static/board/kda-logo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 160px;
  height: 42px;
}
.pk-head .left .text {
  margin-left: 50px;
}
.pk-head .center {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.pk-head .center {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  letter-spacing: 8px;
  color: #8bd8ff;
  font-weight: bold;
}
.pk-head .right {
  width: 200px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}
.pk-head .right .day {
  font-size: 32px;
  color: #ffe607;
  margin: auto 10px;
}
.pk-body {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.pk-body-between {
  background-image: url("../../../static/board/pk-box-user.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  width: 23%;
  padding: 10px 20px;
}
.pk-body-thead {
  height: 40px;
  line-height: 40px;
  text-align: left;
}
.pk-body-tbody {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}
.pk-body-trow {
  display: flex;
  flex-direction: row;
  height: 50px;
  line-height: 52px;
}
.pk-body-trow.r1 {
  background-image: url("../../../static/board/pk-box-user-r1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.pk-body-trow.r2 {
  background-image: url("../../../static/board/pk-box-user-r2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.pk-body-trow.r3 {
  background-image: url("../../../static/board/pk-box-user-r3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.pk-body-trow .col1 {
  width: 80px;
  text-align: center;
}
.pk-body-trow .col1.i1 {
  background-image: url("../../../static/board/pk-box-user-i1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: 21px 26px;
  background-position: center center;
}
.pk-body-trow .col1.i2 {
  background-image: url("../../../static/board/pk-box-user-i2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: 21px 26px;
  background-position: center center;
}
.pk-body-trow .col1.i3 {
  background-image: url("../../../static/board/pk-box-user-i3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: 21px 26px;
  background-position: center center;
}
.pk-body-trow .col2 {
  display: flex;
  flex: 1;
}
.pk-body-trow .col3 {
  width: 120px;
  text-align: left;
}
.pk-body-center {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 20px;
}
.pk-body-team {
  display: flex;
  flex-direction: row;
  background-image: url("../../../static/board/pk-box-team.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: space-between;
  padding: 20px;
}
.process-text {
  color: #e4e7fb;
  height: 30px;
  width: 100%;
  text-align: left;
  line-height: 30px;
  padding-left: 22px;
  margin-top: 10px;
  font-size: 16px;
  background-image: url("../../../static/board/pk-goal.png");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center left;
}
.process-chart {
  background: #3e3682;
  border-radius: 7px;
  height: 14px;
  width: 100%;
}
.process-chart-value {
  background: #ff5a5a;
  border-radius: 7px;
  height: 14px;
}
.pk-body-box {
  width: 25%;
  height: 230px;
  background-image: url("../../../static/board/pk-box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}
.pk-body-box .top {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  height: 72px;
  text-align: right;
  padding-right: 12px;
  padding-top: 12px;
  font-size: 16px;
}
.pk-body-box .top.top1 {
  background-image: url("../../../static/board/icon-pk-top1.png");
}
.pk-body-box .top.top2 {
  background-image: url("../../../static/board/icon-pk-top2.png");
}
.pk-body-box .top.top3 {
  background-image: url("../../../static/board/icon-pk-top3.png");
}
.pk-body-box .top.top4 {
  background-image: url("../../../static/board/icon-pk-top4.png");
}
.pk-body-box .img {
  width: 90px;
  height: 90px;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 40px;
}
.pk-body-box .img .crown {
  background-image: url("../../../static/board/icon-crown.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -20px;
  top: -25px;
  width: 55px;
  height: 52px;
}
.pk-body-box .team {
  margin-top: 20px;
  color: #00ffff;
}
.pk-body-visit {
  background-image: url("../../../static/board/pk-box-bottom.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;
  position: relative;
}
.body-visit-radio {
  position: absolute;
  top: 10px;
  right: 20px;
}
.pk-cost-user {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  font-size: 16px;
}
.cost-user-box {
  height: 46px;
  border-bottom: 1px solid #2d99ff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.cost-user-title {
  display: flex;
  flex-direction: row;
}
.cost-user-rank {
  width: 80px;
  text-align: left;
  color: #2d99ff;
}
.cost-user-name {
  display: flex;
  flex: 1;
}
.cost-user-rate {
  width: 0;
  height: 10px;
  background: #2d99ff;
}
.pk-cost-team {
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}
.cost-team-box {
  height: 120px;
  /* width: 25%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.cost-team-rank,
.cost-team-name {
  text-align: center;
}
.cost-team-img {
  width: 60px;
  height: 60px;
  border: 2px solid;
  border-radius: 60px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.cost-team-box.c1 {
  color: #ff5a5a;
}
.cost-team-box.c1 .cost-team-img {
  border-color: #ff5a5a;
}
.cost-team-box.c2 {
  color: #ffe700;
}
.cost-team-box.c2 .cost-team-img {
  border-color: #ffe700;
}
.cost-team-box.c3 {
  color: #2d99ff;
}
.cost-team-box.c3 .cost-team-img {
  border-color: #2d99ff;
}
.cost-team-box.c4 {
  color: #00ffff;
}
.cost-team-box.c4 .cost-team-img {
  border-color: #00ffff;
}
.margin-left0 {
  margin-left: 0;
}
.ivu-radio-default {
  color: #fff;
  border-color: #6c73ab;
  background-color: #071471;
}
.ivu-radio-wrapper-checked {
  color: #fff;
  border-color: #6c73ab;
  background-color: #2d99ff;
}
</style>